import Link from "next/link";
import { slide as Menu } from "react-burger-menu";

export const HamburgerMenu = () => {
  return (
    <div style={{ position: "absolute" }}>
      <Menu right>
        <Link href="/">FS-UAE</Link>
        <Link href="/about">About</Link>
        <Link href="/download">Download</Link>
        <Link href="/news">News</Link>
        <Link href="/support">Support</Link>
        <Link href="/docs">Docs</Link>
      </Menu>
    </div>
  );
};
