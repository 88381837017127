import Image from "next/future/image";

import styles from "./SiteHeader.module.css";

import icon from "../public/icons/fs-uae.png";
import Link from "next/link";
import { HamburgerMenu } from "./HamburgerMenu";

{
  /* <div
style={{
  backgroundColor: "#cccccc",
  padding: "16px 32px",
}}
>
<div>FS-UAE</div>
</div> */
}

export const SiteHeader = () => {
  return (
    <>
      <header className={styles.header}>
        <div className={styles.content}>
          <Image
            className={styles.icon}
            src={icon}
            width="32"
            height="32"
            alt=""
          />
          <h1 className={styles.title}>
            <Link href="/">
              FS-UAE
              {/* <span className="fs-site-title-weaker">Amiga Emulator</span>*/}
            </Link>
          </h1>
          {/*<!-- <h1>FS-UAE Amiga Emulator</h1>-->*/}
          <nav className={styles.nav}>
            <ul>
              {/*<li><a href="/" className="selected">Home</a></li>-->*/}
              {/*<li><a href="/">Home</a></li>-->*/}
              <li>
                <Link href="/about">About</Link>
              </li>
              <li>
                <Link href="/download">Download</Link>
              </li>
              {/*<li><a href="/features">Features</a></li>-->*/}
              <li>
                <Link href="/news">News</Link>
              </li>
              {/*<li><a href="/tutorials">Tutorials</a></li>-->*/}
              <li>
                <Link href="/support">Support</Link>
              </li>
              <li>
                <Link href="/docs">Docs</Link>
              </li>
              {/*<li><a href="/faq">FAQ</a></li>*/}
            </ul>
          </nav>
        </div>
      </header>
      <HamburgerMenu></HamburgerMenu>
    </>
  );
};
