import { SiteFooter } from "./SiteFooter";
import { SiteHeader } from "./SiteHeader";
import Link from "next/link";
import Head from "next/head";

type Props = {
  children?: JSX.Element | JSX.Element[];
  title: string;
};

export const StandardPage: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <Head>
        <title>{title ? `${title} – FS-UAE` : "FS-UAE default title"}</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div className="fs-header-and-main">
        <SiteHeader></SiteHeader>

        {/*
      <div
        style={{
          backgroundColor: "#aaaaaa",
          padding: "16px 32px",
        }}
      >
        <Link href="/about">About</Link> &middot;{" "}
        <Link href="/discord">Discord</Link> &middot;{" "}
        <Link href="/news">News</Link> &middot;{" "}
        <Link href="/support">Support</Link> &middot;{" "}
        <Link href="/privacy-policy">Privacy policy</Link> &middot;{" "}
        <Link href="/logs">Logs</Link> &middot;{" "}
        <Link href="/features">Features</Link> &middot;{" "}
        <Link href="/donate">Donate</Link> &middot;{" "}
        <Link href="/download">Download</Link> &middot;{" "}
        <Link href="/download-devel">Download-devel</Link> &middot;{" "}
        <Link href="/download-3-0">Download (3.0)</Link>
      </div>

      <div
        style={{
          backgroundColor: "#999999",
          padding: "16px 32px",
        }}
      >
        <Link href="/dev/download">dev/download</Link> &middot;{" "}
        <Link href="/dev/download">dev/docs</Link> &middot;{" "}
        <Link href="/dev/download">dev/docs/options</Link> &middot;{" "}
      </div>
      */}

        <div
          style={{
            alignSelf: "center",
            maxWidth: "1024px",
            width: "100%",
          }}
        >
          {children}
        </div>
      </div>

      {/*<hr style={{ marginTop: "32px" }} />*/}
      <SiteFooter></SiteFooter>
    </>
  );
};
