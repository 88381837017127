import type { NextPage } from "next";
import Head from "next/head";
import Image from "next/image";
import { StandardPage } from "../components/StandardPage";
import styles from "../styles/Home.module.css";

const Home: NextPage = () => {
  {
    /*
    <div className={styles.container}>
      <Head>
        <title>Create Next App</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
  */
  }

  return <StandardPage title="FS-UAE Amiga Emulator"></StandardPage>;
  {
    /*</div>*/
  }
};

export default Home;
