import Image from "next/image";
import Link from "next/link";
import { ExternalLink } from "./ExternalLink";
import styles from "./SiteFooter.module.css";

export const LeftColumn = () => {
  return (
    <div className={styles.column}>
      <h2>About FS-UAE</h2>
      <p>
        FS-UAE is an Amiga emulator based on the excellent emulation code from{" "}
        <ExternalLink href="http://www.winuae.net/">WinUAE</ExternalLink>{" "}
        currently developed and maintained by Toni Wilen, which again builds on
        the work by the earlier authors of WinUAE and the original{" "}
        <ExternalLink href="https://github.com/bernds/UAE">UAE</ExternalLink>{" "}
        emulator.
      </p>
      <p>
        <Link href="/donate">Donate to FS-UAE Development</Link>
      </p>
      <p>
        FS-UAE is copyright © 2011-2022{" "}
        <a href="mailto:frode@fs-uae.net">Frode Solheim</a>, licensed under{" "}
        <ExternalLink href="http://www.gnu.org/licenses/gpl-2.0.html">
          GPLv2
        </ExternalLink>
        . FS-UAE also depends on other free software libraries.
      </p>
    </div>
  );
};

export const MiddleColumn = () => {
  return (
    <div className={styles.column}>
      <h2>Links</h2>
      <ul>
        <li>
          <a
            href="http://www.amigaforever.com"
            title="Purchase legal kickstarts"
          >
            Amiga Forever
          </a>{" "}
          - Purchase legal kickstart ROMs
        </li>
        <li>
          <a
            href="https://crowdin.net/project/fs-uae"
            title="Translation project for FS-UAE"
          >
            Crowdin project page
          </a>{" "}
          - Translations for FS-UAE
        </li>
        <li>
          <a
            href="http://eab.abime.net/forumdisplay.php?f=122"
            title="Support and Amiga hardware forums"
          >
            English Amiga Board
          </a>{" "}
          - Support and Amiga hardware forums
        </li>
        <li>
          <a
            href="https://github.com/FrodeSolheim"
            title="Source code for FS-UAE and Launcher"
          >
            {" "}
            Github account
          </a>{" "}
          - Source code for FS-UAE and Launcher
        </li>
        <li>
          <a href="https://openretro.org/" title="Online game database">
            OpenRetro
          </a>{" "}
          - Online game database
        </li>
        {
          <li>
            <a
              href="http://www.winuae.net/"
              title="The most feature-packed Amiga emulator"
            >
              WinUAE
            </a>{" "}
            - The most feature-packed Amiga emulator
          </li>
        }
      </ul>
    </div>
  );
};

export const RightColumn = () => {
  return (
    <div className={styles.column}>
      <h2>About this website</h2>
      <p>
        This website and most of its content is copyright © 2012-2022{" "}
        <a href="mailto:frode@fs-uae.net">Frode Solheim</a> unless otherwise
        noted. {/*Copyright on visitors' comments is not claimed. */}
        This website does not include paid promotions nor adverts. All income
        is provided by <Link href="/donate">donations</Link>. This website does
        not try to track you. Some information about your visit might be stored
        temporarily for practical purposes. See the{" "}
        <Link href="/privacy-policy">privacy policy</Link> for more
        information.
      </p>
      {/*<p>Comments are moderated to avoid spam, and may take some time to appear.</p>*/}
      {/*<p>This website is powered by <a href="http://www.wordpress.org/">WordPress</a>.</p>*/}
    </div>
  );
};

export const SiteFooter = () => {
  return (
    <footer className={styles.footer}>
      <h1 className="visuallyhidden">Footer</h1>
      <div className={styles.content}>
        <div className={styles.columns}>
          <LeftColumn></LeftColumn>
          <MiddleColumn></MiddleColumn>
        </div>
        <RightColumn></RightColumn>
      </div>
    </footer>
  );
};
